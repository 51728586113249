import { Component, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import VueBarcode from "vue-barcode";
import { IMaskDirective } from "vue-imask";
import DfStore from "@/vue/domain/store/df-store";
import DfUser from "@/vue/domain/user/df-user";
import DfBrand from "@/vue/domain/brand/df-brand";

const DfHeaderMobileComponent = () =>
  Utils.externalComponent2("df-header-mobile");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    VueBarcode,
  },
  directives: {
    imask: IMaskDirective,
  },
})
export default class DfPageAccountCardComponent extends Vue {
  private clubCardNumberError = false;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get clubCardNumber(): DfUser {
    return Utils.getPropertyValue(
      this.currentUser,
      Utils.PROPERTY_USER_CAMI_CARD_NUMBER,
      "STRING"
    );
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get currentUser(): DfUser {
    return this.$store.getters.user;
  }

  get fullName(): string {
    const firstName: string = Utils.getPropertyValue(
      this.currentUser,
      "FIRST_NAME",
      "STRING",
      ""
    );
    const lastName: string = Utils.getPropertyValue(
      this.currentUser,
      "LAST_NAME",
      "STRING",
      ""
    );
    const fullName = [firstName, lastName].join(" ");
    return fullName.trim().length > 0 ? fullName : null;
  }

  get clubCardBackgroundImage(): any {
    return Utils.getPropertyImageUrl(
      this.brand,
      Utils.PROPERTY_CARD_CLUB_MY_AREA_BACKGROUND_IMAGE,
      "string"
    );
  }

  get extraText(): boolean {
    return this.brand.alias === "dpiu-maxidi";
  }

  private goToStorePage(store: DfStore) {
    this.$router
      .push({ name: "store", params: { storeAlias: store.alias } })
      .catch((err) => err);
  }

  beforeMount() {
    this.$root.$emit("loadUser");
  }

  private openMap() {
    window.open(
      `${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(
        this.currentStore
      )}`,
      "_blank"
    );
  }
}
